import React, { useState } from 'react';
import { borderRadius } from '../styles/commonStyle';
import { CenterAlign, GrayButton, PrimaryButton } from '../styles/BasicStyle';

const FeedbackModal = ({ isOpen, onClose, onSubmit }) => {
  const [feedback, setFeedback] = useState('');

  if (!isOpen) return null;

  return (
    <div style={modalStyle}>
      <h3>잠깐! 다운로드 전 의견을 알려주세요</h3>
      <div style={{ marginBottom: '20px' }}>
        사용 중 좋았던 점, 개선해야할 점 및 추가 의견을 적어주시면 선생님께 더
        좋은 서비스를 제공해드릴게요😊
        <br />
        (출결, 담임, 수업, 행정 업무 관련 필요한 서비스 등)
      </div>
      <textarea
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        placeholder="의견을 입력하세요..."
        style={textareaStyle}
      />
      <CenterAlign>
        <PrimaryButton
          onClick={() => onSubmit(feedback)}
          style={{ marginRight: '10px' }}
        >
          제출하고 엑셀 다운로드
        </PrimaryButton>
        <GrayButton
          onClick={() => {
            onClose();
            setFeedback('');
          }}
        >
          닫기
        </GrayButton>
      </CenterAlign>
    </div>
  );
};

const modalStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#f9f9f9', // 부드러운 배경색
  padding: '30px 40px',
  boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)', // 깊이감 추가
  zIndex: 1000,
  borderRadius: '16px', // 더 둥근 모서리
  width: '400px', // 고정된 너비
};
const textareaStyle = {
  width: '95%',
  height: '120px',
  marginBottom: '15px',
  padding: '10px',
  border: '1px solid #ccc',
  borderRadius: '8px',
  fontSize: '14px',
  resize: 'none', // 크기 조절 방지
  outline: 'none', // 포커스 시 외곽선 제거
  boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)', // 안쪽 음영 추가
};

export default FeedbackModal;
